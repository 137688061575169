import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  const photo = (path, size='s12') => {
    return (
      <div className={"photo col " + size}>
        <img src={path} alt="Amarikko reference image"/>
      </div>
    )
  }

  return (
    <div className="App">
      <div className="container">
        <div className="title">°Curation</div>
        <div className="description">
          <p>
            The landscape paintings are drawn on the canvas by the painters seen and thought in their brain who lived in the real world of that period. But in modern times, the art experience is merely that just looking at the pictures in art museums.
            We succeeded in creating a new art experience by connecting the atmosphere between viewers and the artist.
          </p>
          <p>
            In this project, we had to prepare an abundant sample data as we used deep learning to estimate the scenery's temperature. Then, we built a dataset made by geotagged and time stamped picture about 6.7M samples to unite public domained picture dataset(Yahoo Flickr Creative Commons 100M) and geometroligical dataset(Global Historical Climatology Network)
          </p>
          <p>
            We trained a deep learning model with the dataset and created an AI that can estimate the temperature and humidity of the image. By using the AI, we have succeeded in estimating “the painting’s world’s” temperature and humidity scores. Those scores of the viewers’ environment are acquired in real-time and entered into the AI. The AI then refers to public domain data on paintings which has been made available by art galleries all over the world.
          </p>
          <p>
            By placing themselves within the world of the painting, viewers can breathe the same air as the painted scenery and experience art in a new, immersive way.
          </p>
          <p>
            °Curation was unveiled at Art Fair Tokyo (the biggest art trade fair in Japan) that was held between March 7 and March 10, 2019.
            By recreating the same atmosphere, it bridges the art at the time and the present to demonstrate a new way of discovering art. 
          </p>
        </div>
        <div className="video">
          <iframe src="https://drive.google.com/file/d/1xpqZLoefnbTj99F0ztF5s-x4VuOYm7Ab/preview" width="640" height="480" allowFullScreen></iframe>          
        </div>

        <div className="photos">
          <div className="row">
            {photo("/images/curation_image_01.jpg", "s12")}
            {photo("/images/curation_board_cannes_-03.jpg", "s12")}
            {photo("/images/system.png", "s12 m6")}
            {photo("/images/curation_image_03.jpg", "s12 m6")}
            {photo("/images/curation_image_04.jpg", "s12 m6")}
            {photo("/images/curation_image_05.jpg", "s12 m6")}
            {photo("/images/curation_image_06.jpg", "s12")}
           </div>
        </div>
      </div>
    </div>
  );
}

export default App;
